// declare module 'nuxt/schema' {
//   interface AppConfig {
//     title: string
//     description: string
//     theme: {
//       dark: boolean
//       colors: {
//         primary: string
//       }
//     }
//   }
// }
export default defineAppConfig({
  title: 'Daniel Hagen - Personal Portfolio Website',
  description: 'Daniel Hagen\'s personal portfolio website',
  socials: [
    {
      link: 'https://www.linkedin.com/in/daniel-b-hagen',
      icon: 'bxl-linkedin-square',
    },
    {
      link: 'https://twitter.com/dbhagenatx',
      icon: 'bxl-twitter',
    },
    {
      link: 'https://instagram.com/dbhagenatx',
      icon: 'bxl-instagram',
    },
    {
      link: '#contact',
      icon: 'bx-envelope',
    },
  ],
  ui: {
    primary: 'green',
  },
})
