export const appKeepalive = true

export const appHead = {"meta":[{"name":"description","content":"Daniel Hagen's personal portfolio website"},{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"content":"IE=edge,chrome=1","http-equiv":"X-UA-Compatible"},{"name":"theme-color","content":"#3cdbc0"},{"property":"og:title","content":"Daniel Hagen's personal portfolio website"},{"property":"og:locale","content":"en_US"},{"property":"og:url","content":"//"},{"property":"og:type","content":"website"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:site","content":"@dbhagen"}],"link":[{"rel":"icon","type":"image/png","href":"/icons/favicon.ico"},{"rel":"dns-prefetch","href":"https://fonts.googleapis.com"},{"rel":"dns-prefetch","href":"https://fonts.gstatic.com"},{"rel":"dns-prefetch","href":"https://unpkg.com"},{"rel":"dns-prefetch","href":"https://danielhagen.com"},{},{"href":"https://fonts.gstatic.com","rel":"preconnect","crossorigin":"anonymous"},{"as":"style","href":"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Raleway:wght@400;500;600;700&display=swap","rel":"preload","crossorigin":"anonymous"},{"href":"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Raleway:wght@400;500;600;700&display=swap","rel":"stylesheet","crossorigin":"anonymous"},{"href":"https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css","rel":"stylesheet","crossorigin":"anonymous"}],"style":[],"script":[{"defer":true,"type":"module","src":"https://cdn.jsdelivr.net/npm/altcha/dist/altcha.min.js"},{"defer":true,"src":"https://unpkg.com/@alpine-collective/toolkit@1.0.0/dist/cdn.min.js"},{"defer":true,"src":"https://unpkg.com/alpinejs@3.x.x/dist/cdn.min.js"}],"noscript":[],"viewport":"width=device-width, initial-scale=1, viewport-fit=cover","bodyAttrs":{"class":"relative overflow-x-hidden"},"htmlAttrs":{"lang":"en"},"title":"Daniel Hagen - Personal Portfolio Website"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000