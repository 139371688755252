import revive_payload_client_d6VLA8mhS2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1._cbqsfyemoitxdhnhe5uao2zsb4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Uw4248BXhU from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1._cbqsfyemoitxdhnhe5uao2zsb4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZhaXTD8G2G from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1._cbqsfyemoitxdhnhe5uao2zsb4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_XXhyZWwch7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1._cbqsfyemoitxdhnhe5uao2zsb4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hlF1ZcGrkF from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1._cbqsfyemoitxdhnhe5uao2zsb4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_L3ihLtECQB from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1._cbqsfyemoitxdhnhe5uao2zsb4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ED1bCgIodj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1._cbqsfyemoitxdhnhe5uao2zsb4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_vkpxJfNYBG from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1._cbqsfyemoitxdhnhe5uao2zsb4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_GJO68p8KGt from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_axios@1.7.2_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.10_ters_fii6inwvjnadumtdd72xh7ve7y/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_zo1f2UgTss from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_axios@1.7.2_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.10_ters_fii6inwvjnadumtdd72xh7ve7y/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_Cjy29plARd from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_axios@1.7.2_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.10_ters_fii6inwvjnadumtdd72xh7ve7y/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_JOp3LWoYa0 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
export default [
  revive_payload_client_d6VLA8mhS2,
  unhead_Uw4248BXhU,
  router_ZhaXTD8G2G,
  payload_client_XXhyZWwch7,
  navigation_repaint_client_hlF1ZcGrkF,
  check_outdated_build_client_L3ihLtECQB,
  chunk_reload_client_ED1bCgIodj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vkpxJfNYBG,
  slideovers_GJO68p8KGt,
  modals_zo1f2UgTss,
  colors_Cjy29plARd,
  plugin_client_JOp3LWoYa0,
  posthog_client_fUucxKWhZE
]