<template>
  <NuxtLoadingIndicator color="repeating-linear-gradient(to right,var(--bg-primary) 0%, var(--bg-primary) 100%)" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import { PostHog } from 'posthog-node'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { data: someData, error } = await useAsyncData('ctaText', async () => {
  const runtimeConfig = useRuntimeConfig()
  const posthog = new PostHog(
    runtimeConfig.public.posthogPublicKey,
    { host: runtimeConfig.public.posthogHost },
  )

  const cookies = useCookie(`ph_${runtimeConfig.public.posthogPublicKey}_posthog`)
  if (cookies && cookies.value) {
    try {
      const distinctId = cookies.value.distinct_id // or you can use your user's email, for example.
      posthog.capture({
        distinctId: distinctId,
        event: 'user_did_something"',
      })
      await posthog.shutdown()
    }
    catch (error) {
      console.log(error)
    }
  }
  return 'Some data'
})
</script>
